import React, { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../../hooks/useRouter";
import {
    Text,
    Stack,
    Button,
    Box,
    Flex,
    Input,
    InputGroup,
    InputRightElement,
    Image,
    Center,
    Spacer,
    Divider,
    SimpleGrid,
    HStack,
    Container
} from "@chakra-ui/react";
import GeneralTopBar from "../../../../component/general-top-bar";
import { PageSettings } from "../../../../routes/setting";
import ApiEngine from "../../../../common/api_engine";
import Routes from "../../../../common/api_routes";
import { Pages } from "../../../../routes/page";
import { showCustomDialog } from "../../../../application/action/app_action";
import { isObjectEmpty, numberWithCurrencyFormat, stringIsNullOrEmpty, permutation } from "../../../../common/util";
import { ApiKey, BG_TYPE, OrderMode, _WEB_VERSION, SessionKey } from "../../../../common/constant";
import CustomPicker from "../../../../component/custom-picker";
import { setBusy, setIdle } from "../../../../application/action/app_action";
import moment from "moment";
import {
    MAGNUM_POOL_NAME,
    DAMACAI_POOL_NAME,
    TOTO_POOL_NAME,
    SINGAPORE_POOL_NAME,
    SABAH_POOL_NAME,
    SANDAKAN_POOL_NAME,
    CASH_SWEEP_POOL_NAME,
    GRAND_DRAGON_POOL_NAME,
    NINE_LOTTO_POOL_NAME,
    _POOL_DETAILS,
    AMOUNT_TYPE_BIG,
    AMOUNT_TYPE_SMALL,
    AMOUNT_TYPE_2A,
    AMOUNT_TYPE_2C,
    AMOUNT_TYPE_2SB,
    AMOUNT_TYPE_2SC,
    AMOUNT_TYPE_2SD,
    AMOUNT_TYPE_2SE,
    AMOUNT_TYPE_3A,
    AMOUNT_TYPE_3C,
    AMOUNT_TYPE_3SB,
    AMOUNT_TYPE_3SC,
    AMOUNT_TYPE_3SD,
    AMOUNT_TYPE_3SE,
    AMOUNT_TYPE_4A,
    AMOUNT_TYPE_4SB,
    AMOUNT_TYPE_4SC,
    AMOUNT_TYPE_4SD,
    AMOUNT_TYPE_4SE,
    AMOUNT_TYPE_4C,
    AMOUNT_TYPE_5D,
    AMOUNT_TYPE_6D,
    TOTO_POOL_VALUE,
    GRAND_DRAGON_POOL_VALUE,
    BOX_ORDER_TYPE,
    IBOX_ORDER_TYPE,
    PH_ORDER_TYPE,
    PT_ORDER_TYPE,
    NINE_LOTTO_POOL_VALUE
} from '../../../../common/constant';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    const { t } = useTranslation();
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    const _PLAYER_ID = _location?.state?.playerId;
    const _GAME_NAME = _location?.state?.gameName;
    const [selectedPlayer, setSelectedPlayer] = useState();
    const [availableDrawDate, setAvailableDrawDate] = useState([]);
    const [selectedDrawDate, setSelectedDrawDate] = useState([]);
    const [selectedPool, setSelectedPool] = useState([]);
    const [selectedOrderMode, setSelectedOrderMode] = useState(_location?.state?.orderMode ?? OrderMode._ORDER_MODE_4D);
    const [keyboardShown, setKeyboardShown] = useState(false);
    const [inputField, setInputField] = useState([]);
    const [amountField, setAmountField] = useState([]);
    const [focusField, setFocusField] = useState();
    const [amountFocusField, setAmountFocusField] = useState('');
    const [betItem, setBetItem] = useState([]);
    const [poolField, setPoolField] = useState(_POOL_DETAILS);
    const [lineTotal, setLineTotal] = useState(0);
    const [orderType, setOrderType] = useState();
    const [showOrderTypePicker, setShowOrderTypePicker] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [ticketTotal, setTicketTotal] = useState(0);
    const keyboard = useRef();
    const _DEFAULT_INPUT_AMOUNT = [
        {
            orderMode: OrderMode._ORDER_MODE_4D,
            inputs: [
                {
                    label: "BIG",
                    id: "InputIdBig",
                    type: AMOUNT_TYPE_BIG,
                    amount: 0
                },
                {
                    label: "SMALL",
                    id: "InputIdSmall",
                    type: AMOUNT_TYPE_SMALL,
                    amount: 0
                },
                {
                    label: "4A",
                    id: "InputId4A",
                    type: AMOUNT_TYPE_4A,
                    amount: 0
                },
                {
                    label: "4SB",
                    id: "InputId4SB",
                    type: AMOUNT_TYPE_4SB,
                    amount: 0
                },
                {
                    label: "4SC",
                    id: "InputId4SC",
                    type: AMOUNT_TYPE_4SC,
                    amount: 0
                },
                {
                    label: "4SD",
                    id: "InputId4SD",
                    type: AMOUNT_TYPE_4SD,
                    amount: 0
                },
                {
                    label: "4SE",
                    id: "InputId4SE",
                    type: AMOUNT_TYPE_4SE,
                    amount: 0
                },
                {
                    label: "4C",
                    id: "InputId4C",
                    type: AMOUNT_TYPE_4C,
                    amount: 0
                }
            ]
        },
        {
            orderMode: OrderMode._ORDER_MODE_5D,
            inputs: [
                {
                    label: "5D",
                    id: "InputId5D",
                    type: AMOUNT_TYPE_5D,
                    amount: 0
                },
            ],
            availablePools: [TOTO_POOL_VALUE]
        },
        {
            orderMode: OrderMode._ORDER_MODE_6D,
            inputs: [
                {
                    label: "6D",
                    id: "InputId6D",
                    type: AMOUNT_TYPE_6D,
                    amount: 0
                },
            ],
            availablePools: [TOTO_POOL_VALUE, GRAND_DRAGON_POOL_VALUE, NINE_LOTTO_POOL_VALUE]
        },
        {
            orderMode: OrderMode._ORDER_MODE_3D,
            inputs: [
                {
                    label: "3A",
                    id: "InputId3A",
                    type: AMOUNT_TYPE_3A,
                    amount: 0
                },
                {
                    label: "3C",
                    id: "InputId3C",
                    type: AMOUNT_TYPE_3C,
                    amount: 0
                },
                {
                    label: "3SB",
                    id: "InputId3SB",
                    type: AMOUNT_TYPE_3SB,
                    amount: 0
                },
                {
                    label: "3SC",
                    id: "InputId3SC",
                    type: AMOUNT_TYPE_3SC,
                    amount: 0
                },
                {
                    label: "3SD",
                    id: "InputId3SD",
                    type: AMOUNT_TYPE_3SD,
                    amount: 0
                },
                {
                    label: "3SE",
                    id: "InputId3SE",
                    type: AMOUNT_TYPE_3SE,
                    amount: 0
                }
            ],
        },
        {
            orderMode: OrderMode._ORDER_MODE_2D,
            inputs: [
                {
                    label: "2A",
                    id: "InputId2A",
                    type: AMOUNT_TYPE_2A,
                    amount: 0
                },
                {
                    label: "2C",
                    id: "InputId2C",
                    type: AMOUNT_TYPE_2C,
                    amount: 0
                },
                {
                    label: "2SB",
                    id: "InputId2SB",
                    type: AMOUNT_TYPE_2SB,
                    amount: 0
                },
                {
                    label: "2SC",
                    id: "InputId2SC",
                    type: AMOUNT_TYPE_2SC,
                    amount: 0
                },
                {
                    label: "2SD",
                    id: "InputId2SD",
                    type: AMOUNT_TYPE_2SD,
                    amount: 0
                },
                {
                    label: "2SE",
                    id: "InputId2SE",
                    type: AMOUNT_TYPE_2SE,
                    amount: 0
                }
            ]
        }
    ];
    const _ORDER_TYPE_OPTION = [
        { label: 'NORM', value: '' },
        { label: 'BOX', value: BOX_ORDER_TYPE },
        { label: 'IBOX', value: IBOX_ORDER_TYPE },
        { label: 'P-H', value: PH_ORDER_TYPE },
        { label: 'P-T', value: PT_ORDER_TYPE }
    ]

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (stringIsNullOrEmpty(_PLAYER_ID)) {
            _router.back();
        }
        else {
            getPlayerDetail();
            generateAvailableDrawDate();

            if (!stringIsNullOrEmpty(sessionStorage.getItem(SessionKey._BET_ITEM))) {
                setBetItem(JSON.parse(sessionStorage.getItem(SessionKey._BET_ITEM)));
            }

            setIsFirstLoad(false);
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
        }

        _context.toggleBg(BG_TYPE.swc);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        let field = [];
        let amountField = _DEFAULT_INPUT_AMOUNT.find(i => i.orderMode == selectedOrderMode);
        let pool = _POOL_DETAILS;
        let pickedPool = [];

        for (var i = 0; i < parseInt(selectedOrderMode[0]); i++) {
            field.push({ id: i, value: '' });
        }

        if (amountField?.availablePools) {
            pool = _POOL_DETAILS.filter(i => amountField.availablePools.some(p => p == i.id));

            if (pool.length == 1) {
                pickedPool = [pool[0]];
            }
        }

        setInputField(field);
        setAmountField(amountField.inputs);
        setPoolField(pool);
        setSelectedPool(pickedPool);
        setFocusField(0);
        setOrderType(_ORDER_TYPE_OPTION[0]);

        let selectedModeIndex = betItem.findIndex(b => b.Type == selectedOrderMode.toLowerCase());

        if (selectedModeIndex > -1) {
            setSelectedDrawDate(betItem[selectedModeIndex]['DrawDates'])
        }
        else if (!isFirstLoad) {
            setSelectedDrawDate([availableDrawDate[0]]);
        }
    }, [selectedOrderMode])

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (focusField != -1 || !stringIsNullOrEmpty(amountFocusField)) {
            setKeyboardShown(true);
        }
        else {
            setKeyboardShown(false);
        }
    }, [focusField, amountFocusField])

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        let orderNumber = inputField.map(i => i.value).join('');
        let total = 0;
        amountField.map(item => total += Number(item.amount));

        if (orderNumber.length == parseInt(selectedOrderMode[0])) {
            if (orderType.value == BOX_ORDER_TYPE) {
                let permutations = permutation(orderNumber, { unique: true }).length;
                total = total * permutations;
            }
            else if (orderType.value === PH_ORDER_TYPE) {
                let permutations = permutation(orderNumber.substring(1), { unique: true }).length;
                total = total * permutations;
            }
            else if (orderType.value === PT_ORDER_TYPE) {
                let permutations = permutation(orderNumber.slice(0, -1), { unique: true }).length;
                total = total * permutations;
            }
        }

        setLineTotal(total * selectedDrawDate.length * selectedPool.length);
    }, [selectedDrawDate, selectedPool, amountField, orderType]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (betItem.length > 0) {
            let tempTicketTotal = 0;
            let currOrderMode = betItem.findIndex(b => b.Type == selectedOrderMode.toLowerCase());

            if (currOrderMode > -1) {
                betItem[currOrderMode].OrderDetails.map(orderDetail => {
                    tempTicketTotal += Number(orderDetail.Total);
                });
            }

            setTicketTotal(tempTicketTotal);
        }
    }, [betItem, selectedOrderMode]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    const onBackButtonEvent = (e) => {
        e.preventDefault();
        _dispatch(showCustomDialog({
            success: true,
            title: t('CONFIRMATION'),
            content: betItem.length > 0 ? t('EXIT_ORDER_CONFIRMATION') : t('EXIT_CONFIRMATION'),
            onConfirm: () => {
                sessionStorage.removeItem(SessionKey._BET_ITEM);
                _router.back();
            },
            onCancel: () => { },
            horizontalBtn: false
        }))
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const getPlayerDetail = async () => {
        try {
            var response = await ApiEngine.get(Routes._SWC_GET_PLAYER_DETAILS + "?id=" + _PLAYER_ID);

            if (!response[ApiKey._API_SUCCESS_KEY]) {
                throw (response.Message ?? response.message ?? response.error ?? response.title);
            }
            setSelectedPlayer(response[ApiKey._API_DATA_KEY]);
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: t(err) }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const generateAvailableDrawDate = () => {
        var drawDate = [];
        const DRAWDATESCOUNT = 7;
        var today = moment();

        // If current hour exceed 7.25pm
        if (moment().zone('+0800').format('HH:mm') >= "19:25") {
            today = today.add(1, 'days');
        }

        if (moment().zone('+0800').format('HH:mm') >= "19:01" && moment().zone('+0800').format('HH:mm') <= "19:25") {
            setPoolField(_POOL_DETAILS.filter(p => p.id == NINE_LOTTO_POOL_VALUE));
            setSelectedPool(_POOL_DETAILS.filter(p => p.id == NINE_LOTTO_POOL_VALUE));
        }

        for (var i = 0; i < DRAWDATESCOUNT; i++) {
            var newDate = moment(today, "YYYYMMDD").add('d', i);
            drawDate.push(newDate.format('YYYYMMDD'));
        }

        setAvailableDrawDate(drawDate);

        if (!stringIsNullOrEmpty(sessionStorage.getItem(SessionKey._BET_ITEM))) {
            let sessionBetItem = JSON.parse(sessionStorage.getItem(SessionKey._BET_ITEM));
            let currModeIndex = sessionBetItem.findIndex(s => s.Type == selectedOrderMode.toLowerCase());

            if (currModeIndex > -1) {
                setSelectedDrawDate(sessionBetItem[currModeIndex].DrawDates);
            }
        }
        else {
            setSelectedDrawDate([drawDate[0]]);
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const getPoolImgByTxt = (txt) => {
        var poolImg;
        var str = txt.toUpperCase();

        if (str.indexOf('MAGNUM') > -1 || str.indexOf(MAGNUM_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-magnum.png');
        }
        else if (str.indexOf('DAMACAI') > -1 || str.indexOf(DAMACAI_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-damacai.png');
        }
        else if (str.indexOf('SINGAPORE') > -1 || str.indexOf(SINGAPORE_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-singapore.png');
        }
        else if (str.indexOf('SABAH') > -1 || str.indexOf(SABAH_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-sabah88.png');
        }
        else if (str.indexOf('SANDAKAN') > -1 || str.indexOf('STC') > -1 || str.indexOf(SANDAKAN_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-stc.png');
        }
        else if (str.indexOf('CASH') > -1 || str.indexOf(CASH_SWEEP_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-big-cash.png');
        }
        else if (str.indexOf('TOTO') > -1 || str.indexOf(TOTO_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-toto.png');
        }
        else if (str.indexOf('GD') > -1 || str.indexOf(GRAND_DRAGON_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-gd.png');
        }
        else if (str.indexOf('NINE') > -1 || str.indexOf(NINE_LOTTO_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-ninelotto.png');
        }

        return poolImg;
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function onKeypadChange(keypadValue) {
        if (focusField != -1) {
            let currentInputField = [...inputField];
            currentInputField[focusField]['value'] = keypadValue.slice(-1);

            if (focusField < inputField.length - 1) {
                setFocusField(focusField + 1);
            }

            setInputField(currentInputField);
        }
        else if (!stringIsNullOrEmpty(amountFocusField)) {
            let currentInputField = [...amountField];
            let focusIndex = currentInputField.findIndex(c => c.id == amountFocusField);
            currentInputField[focusIndex]['amount'] = `${currentInputField[focusIndex]['amount'] == 0 ? '' : currentInputField[focusIndex]['amount']}${keypadValue.slice(-1)}`;

            setAmountField(currentInputField);
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function onKeypadKeyPress(key) {
        switch (key) {
            case "{hide}":
                setFocusField(-1);
                setAmountFocusField();
                break;
            case "{add}":
                saveOrder();
                break;
            case "{lucky}":
                _dispatch(setBusy());

                let numberField = [...inputField];
                for (var i = 0; i < numberField.length; i++) {
                    numberField[i]['value'] = Math.floor(Math.random() * 10);
                }
                setInputField(numberField);

                _dispatch(setIdle());
                break;
            case "{prev}":
                if (focusField != -1) {
                    if (focusField != 0) {
                        setFocusField(focusField - 1);
                    }
                }
                else if (!stringIsNullOrEmpty(amountFocusField)) {
                    let currIndex = amountField.findIndex(a => a.id == amountFocusField);

                    if (currIndex != 0) {
                        setAmountFocusField(amountField[currIndex - 1].id);
                    }
                    else {
                        setAmountFocusField();
                        setFocusField(inputField.length - 1);
                    }
                }
                break;
            case "{next}":
                if (focusField != -1) {
                    if (focusField < inputField.length - 1) {
                        setFocusField(focusField + 1);
                    }
                    else {
                        setFocusField(-1);
                        setAmountFocusField(amountField[0]['id']);
                    }
                }
                else if (!stringIsNullOrEmpty(amountFocusField)) {
                    let currIndex = amountField.findIndex(a => a.id == amountFocusField);

                    if (currIndex < amountField.length - 1) {
                        setAmountFocusField(amountField[currIndex + 1].id);
                    }
                }
                break;
            case "{back}":
                if (focusField != -1) {
                    let currentInputField = [...inputField];
                    if (stringIsNullOrEmpty(inputField[focusField]['value'])) {
                        if (focusField != 0) {
                            currentInputField[focusField - 1]['value'] = '';
                            setFocusField(focusField - 1);
                        }
                    }
                    else {
                        currentInputField[focusField]['value'] = '';
                        setInputField(currentInputField);
                    }
                }
                else if (!stringIsNullOrEmpty(amountFocusField)) {
                    let currentInputField = [...amountField];
                    let currIndex = amountField.findIndex(a => a.id == amountFocusField);
                    currentInputField[currIndex]['amount'] = !stringIsNullOrEmpty(currentInputField[currIndex]['amount']) ? currentInputField[currIndex]['amount'].slice(0, -1) : '0';

                    setAmountField(currentInputField);
                }
                break;
            default:
                break;
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function saveOrder() {
        try {
            _dispatch(setBusy());
            let orderNumber = inputField.map(i => i.value).join('');

            if (selectedDrawDate.length === 0) {
                throw t('DRAW_DATE_ERROR');
            }
            else if (selectedPool.length === 0) {
                throw t('POOL_ERROR');
            }
            else if (orderNumber.length != parseInt(selectedOrderMode[0])) {
                throw t('ORDER_NUMBER_ERROR');
            }
            else if (lineTotal == 0) {
                throw t('ORDER_AMOUNT_ERROR');
            }

            let amountDetails = [];

            amountField.map(item => {
                if (parseInt(item.amount) > 0) {
                    amountDetails.push({
                        "Type": item.type,
                        "Amount": parseInt(item.amount)
                    });
                }
            });

            if (isObjectEmpty(betItem)) {
                setBetItem([{
                    "DrawDates": selectedDrawDate,
                    "Type": selectedOrderMode.toLowerCase(),
                    "OrderDetails": [{
                        "Pools": selectedPool,
                        "Number": orderNumber,
                        "BoxIbox": orderType.value,
                        "AmountDetails": amountDetails,
                        "Total": lineTotal
                    }],
                }]);
                sessionStorage.setItem(SessionKey._BET_ITEM, JSON.stringify([{
                    "DrawDates": selectedDrawDate,
                    "Type": selectedOrderMode.toLowerCase(),
                    "OrderDetails": [{
                        "Pools": selectedPool,
                        "Number": orderNumber,
                        "BoxIbox": orderType.value,
                        "AmountDetails": amountDetails,
                        "Total": lineTotal
                    }],
                }]))
            }
            else {
                let newBetItem = [...betItem];
                let currentOrderModeIndex = newBetItem.findIndex(b => b.Type == selectedOrderMode.toLowerCase());

                if (currentOrderModeIndex > -1) {
                    newBetItem[currentOrderModeIndex]['OrderDetails'].push({
                        "Pools": selectedPool,
                        "Number": orderNumber,
                        "BoxIbox": orderType.value,
                        "AmountDetails": amountDetails,
                        "Total": lineTotal
                    });
                }
                else {
                    newBetItem.push({
                        "DrawDates": selectedDrawDate,
                        "Type": selectedOrderMode.toLowerCase(),
                        "OrderDetails": [{
                            "Pools": selectedPool,
                            "Number": orderNumber,
                            "BoxIbox": orderType.value,
                            "AmountDetails": amountDetails,
                            "Total": lineTotal
                        }],
                    });
                }

                setBetItem(newBetItem);
                sessionStorage.setItem(SessionKey._BET_ITEM, JSON.stringify(newBetItem));
            }

            clearField();
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
        finally {
            _dispatch(setIdle());
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function clearField() {
        setSelectedPool([]);

        let field = [];
        for (var i = 0; i < parseInt(selectedOrderMode[0]); i++) {
            field.push({ id: i, value: '' });
        }

        setInputField(field);
        setAmountField(_DEFAULT_INPUT_AMOUNT.find(i => i.orderMode == selectedOrderMode).inputs);
        setFocusField(0);
        setAmountFocusField();
        setOrderType(_ORDER_TYPE_OPTION[0]);
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function submitBet() {
        try {
            let currModeIndex = betItem.findIndex(b => b.Type == selectedOrderMode.toLowerCase());
            var tempOrderDetails = [];
            betItem[currModeIndex].OrderDetails.map((orderDetail, index) => {
                var tempOrderDetail = {
                    "SequenceId": index + 1,
                    "Pools": orderDetail.Pools.map(i => i.id),
                    "Number": orderDetail.Number,
                    "BoxIbox": orderDetail.BoxIbox,
                    "AmountDetails": orderDetail.AmountDetails
                };
                tempOrderDetails.push(tempOrderDetail);
            });

            var params = JSON.stringify({
                "PlayerId": selectedPlayer.id,
                "DrawDates": betItem[currModeIndex].DrawDates,
                "Type": selectedOrderMode,
                "OrderDetails": tempOrderDetails,
                "VersionName": _WEB_VERSION
            });

            var responseJson = await ApiEngine.post(Routes._SWC_MAKE_ORDER, params, {
                headers: {
                    "content-type": ApiKey._API_APPLICATION_JSON
                }
            });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson.Message ?? responseJson.message ?? responseJson.error ?? responseJson.title;
            }

            let newBetItem = [...betItem];
            newBetItem.splice(currModeIndex, 1);

            setBetItem(newBetItem);
            sessionStorage.removeItem(SessionKey._BET_ITEM);
            _router.navigate(Pages.SWC_ORDER_SUCCESS, { orderResponse: responseJson[ApiKey._API_DATA_KEY], playerId: selectedPlayer.id }, true);
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    return (
        <div className='body-content'>
            <GeneralTopBar bgColor="#ebffe3" title={'BETTING'} useSimpleBackIcon blackTitle titleAlignLeft expandRightComponent rightComponent={
                <Flex justify={'center'} align={'center'} pr={1.5}>
                    <Text color={'black'} as={'b'} fontSize={'sm'}>{t('SCORE')} :</Text>
                    <Text color='#08CD34' as={'b'} fontSize={'sm'}>RM {numberWithCurrencyFormat(selectedPlayer?.gameScore ?? 0)}</Text>
                    <Spacer />
                    <Button
                        borderRadius={30}
                        variant={'solid'}
                        size={'xs'}
                        bg={'#08CD34'}
                        color={'white'}
                        fontSize={'md'}
                        alignItems={'baseline'}
                        onClick={() => _router.navigate(Pages._CONVERT_E_CREDIT_OR_E_POINT, {
                            selectedVendor: {
                                label: _GAME_NAME
                            },
                            selectedPlayer: {
                                id: selectedPlayer.id,
                                vendorPlayerid: selectedPlayer.vendorPlayerid,
                                gameScore: selectedPlayer.gameScore
                            },
                            isRequiredNavigateBack: true
                        })}>+</Button>
                </Flex>
            } />
            {/* DRAW DATE */}
            <Flex justifyContent={'space-evenly'}>
                {
                    availableDrawDate.map((item, index) => {
                        let isSelected = selectedDrawDate.findIndex(d => d == item) > -1;
                        return (
                            <Box
                                key={index}
                                p={2}
                                bg={isSelected ? '#08CD34' : '#F5F4FA'}
                                borderRadius={10}
                                onClick={() => {
                                    let currentModeIndex = betItem.findIndex(b => b.Type == selectedOrderMode.toLowerCase());

                                    if (currentModeIndex > -1 && betItem[currentModeIndex]['OrderDetails'].length > 0) {
                                        _dispatch(showCustomDialog({ success: false, content: t('SELECT_DRAW_DATE_ERROR') }));
                                    }
                                    else {
                                        if (isSelected) {
                                            setSelectedDrawDate(selectedDrawDate.filter(d => d != item));
                                        }
                                        else {
                                            setSelectedDrawDate([...selectedDrawDate, item]);
                                        }
                                    }
                                }}
                            >
                                <Stack spacing={0} textAlign={'center'}>
                                    <Text as={'b'} color={isSelected ? 'white' : '#54595F'}>
                                        {moment(item).format('ddd')}
                                    </Text>
                                    <Text as={'b'} color={isSelected ? 'white' : '#54595F'}>
                                        {moment(item).format('DD/MM')}
                                    </Text>
                                </Stack>
                            </Box>
                        )
                    })
                }
            </Flex>
            {/* POOL */}
            <Flex justifyContent={'space-evenly'} marginBlock={2}>
                {
                    poolField.map((item, index) => {
                        let isSelected = selectedPool.findIndex(s => s.id == item.id) > -1;
                        return (
                            <Box
                                key={index}
                                bg={'white'}
                                borderWidth={isSelected ? 2.5 : 0}
                                borderColor={'#228B22'}
                                onClick={() => {
                                    if (isSelected) {
                                        setSelectedPool(selectedPool.filter(d => d.id != item.id));
                                    }
                                    else {
                                        setSelectedPool([...selectedPool, item]);
                                    }
                                }}>
                                <Center>
                                    <img style={{
                                        width: '38px',
                                        height: '38px',
                                        filter: `grayscale(${isSelected ? '0%' : '100%'})`
                                    }}
                                        src={getPoolImgByTxt(item.name)} />
                                </Center>
                            </Box>
                        )
                    })
                }
            </Flex>
            {/* ORDER MODE */}
            <SimpleGrid spacing={0} columns={5}>
                <Box
                    borderWidth={2}
                    borderColor={'#08CD34'}
                    p={1}
                    bg={selectedOrderMode == OrderMode._ORDER_MODE_4D ? '#08CD34' : 'white'}
                    onClick={() => setSelectedOrderMode(OrderMode._ORDER_MODE_4D)}>
                    <Text
                        fontWeight={'bold'}
                        textAlign={'center'}
                        fontSize={'sm'}
                        color={selectedOrderMode == OrderMode._ORDER_MODE_4D ? 'white' : '#08CD34'}>
                        {OrderMode._ORDER_MODE_4D}
                    </Text>
                </Box>
                <Box
                    borderWidth={2}
                    borderColor={'#08CD34'}
                    borderLeftWidth={0} p={1}
                    bg={selectedOrderMode == OrderMode._ORDER_MODE_5D ? '#08CD34' : 'white'}
                    onClick={() => setSelectedOrderMode(OrderMode._ORDER_MODE_5D)}>
                    <Text
                        fontWeight={'500'}
                        textAlign={'center'}
                        fontSize={'sm'}
                        color={selectedOrderMode == OrderMode._ORDER_MODE_5D ? 'white' : '#08CD34'}>
                        {OrderMode._ORDER_MODE_5D}
                    </Text>
                </Box>
                <Box
                    borderWidth={2}
                    borderColor={'#08CD34'}
                    borderLeftWidth={0}
                    p={1}
                    bg={selectedOrderMode == OrderMode._ORDER_MODE_6D ? '#08CD34' : 'white'}
                    onClick={() => setSelectedOrderMode(OrderMode._ORDER_MODE_6D)}>
                    <Text
                        fontWeight={'500'}
                        textAlign={'center'}
                        fontSize={'sm'}
                        color={selectedOrderMode == OrderMode._ORDER_MODE_6D ? 'white' : '#08CD34'}>
                        {OrderMode._ORDER_MODE_6D}
                    </Text>
                </Box>
                <Box
                    borderWidth={2}
                    borderColor={'#08CD34'}
                    borderLeftWidth={0} p={1}
                    bg={selectedOrderMode == OrderMode._ORDER_MODE_3D ? '#08CD34' : 'white'}
                    onClick={() => setSelectedOrderMode(OrderMode._ORDER_MODE_3D)}>
                    <Text
                        fontWeight={'500'}
                        textAlign={'center'}
                        fontSize={'sm'}
                        color={selectedOrderMode == OrderMode._ORDER_MODE_3D ? 'white' : '#08CD34'}>
                        {OrderMode._ORDER_MODE_3D}
                    </Text>
                </Box>
                <Box
                    borderWidth={2}
                    borderColor={'#08CD34'}
                    borderLeftWidth={0}
                    p={1}
                    bg={selectedOrderMode == OrderMode._ORDER_MODE_2D ? '#08CD34' : 'white'}
                    onClick={() => setSelectedOrderMode(OrderMode._ORDER_MODE_2D)}>
                    <Text
                        fontWeight={'500'}
                        textAlign={'center'}
                        fontSize={'sm'}
                        color={selectedOrderMode == OrderMode._ORDER_MODE_2D ? 'white' : '#08CD34'}>
                        {OrderMode._ORDER_MODE_2D}
                    </Text>
                </Box>
            </SimpleGrid>
            {/* NUMBER & TYPE  */}
            <Flex mt={1.5}>
                <Spacer />
                <Stack align={'center'} spacing={0}>
                    <Text color='#08CD34' fontWeight='bold'>{t('ORDER_NUMBER')}</Text>
                    <HStack maxW={'50vw'}>
                        {
                            inputField.map((item, index) => {
                                return (
                                    <Input
                                        key={`${index}_${selectedOrderMode}`}
                                        htmlSize={1}
                                        width='auto'
                                        bg='#F5F4FA'
                                        value={item.value}
                                        type='text'
                                        maxLength={1}
                                        onClick={() => {
                                            setFocusField(item.id);
                                            setAmountFocusField();
                                        }}
                                        style={{
                                            padding: '10px',
                                            borderWidth: focusField == item.id ? '2px' : '0px',
                                            borderColor: focusField == item.id ? '#228B22' : 'white'
                                        }}
                                        readOnly />
                                )
                            })
                        }
                    </HStack>
                </Stack>
                <Spacer />
                <Stack align={'center'} spacing={0}>
                    <Text color='#08CD34' fontWeight='bold'>{t('TYPE')}</Text>
                    <HStack>
                        <InputGroup w={'30vw'}>
                            <Input
                                isReadOnly
                                value={orderType?.label}
                                variant='flushed'
                                bg={'#F5F4FA'}
                                pl={3}
                                ml={3}
                                onClick={() => setShowOrderTypePicker(!showOrderTypePicker)}
                            />
                            <InputRightElement>
                                <Image boxSize={'10px'} src={require('../../../../assets/images/triangular-black-arrow-pointing-down.png')} />
                            </InputRightElement>
                        </InputGroup>
                    </HStack>
                </Stack>
                <Spacer />
            </Flex>
            {/* AMOUNT */}
            <SimpleGrid columns={selectedOrderMode == OrderMode._ORDER_MODE_5D || selectedOrderMode == OrderMode._ORDER_MODE_6D ? 1 : 4} mt={1}>
                {
                    amountField.map((item, index) => {
                        return (
                            <Stack align={'center'} spacing={0}>
                                <Text color={'#54595F'}>{item.label}</Text>
                                <Input
                                    key={`${index}_${item.id}`}
                                    htmlSize={3}
                                    width='auto'
                                    bg='#F5F4FA'
                                    value={item.amount}
                                    type='text'
                                    maxLength={1}
                                    onClick={() => {
                                        setFocusField(-1);
                                        setAmountFocusField(item.id);
                                    }}
                                    style={{
                                        textAlign: 'center',
                                        borderWidth: amountFocusField == item.id ? '2px' : '0px',
                                        borderColor: amountFocusField == item.id ? '#228B22' : 'white',
                                    }}
                                    readOnly />
                            </Stack>
                        )
                    })
                }
            </SimpleGrid>
            <Container marginBlock={1} p={0.5}>
                <Flex align={'center'}>
                    <Text color={'#54595F'} fontWeight={'750'} fontSize={'sm'}>{t('TOTAL')}</Text>
                    <Spacer />
                    <Text color={'#54595F'} fontWeight={'750'} fontSize={'md'} mr={1.5}>{`RM ${numberWithCurrencyFormat(lineTotal)}`}</Text>
                    <Button variant={'solid'} bg={'#08CD34'} color={'white'} size={'sm'} onClick={() => saveOrder()}>{t('ADD')}</Button>
                </Flex>
            </Container>
            <Divider className="app-divider-full-width" />
            {
                betItem.findIndex(b => b.Type == selectedOrderMode.toLowerCase()) > -1 &&
                <Stack spacing={0}>
                    <Text color={'black'} fontWeight={500}>{`${t('DRAW_DATE')}: ${betItem.find(b => b.Type == selectedOrderMode.toLowerCase()).DrawDates.map(date => moment(date, 'YYYYMMDD').format('DD/MM (ddd)').toUpperCase()).join(', ')}`}</Text>
                    {
                        betItem.find(b => b.Type == selectedOrderMode.toLowerCase())?.OrderDetails.map((orderDetail, index) => {
                            return (
                                <Flex p={1} bg={'white'} key={index} borderBottomWidth={1} borderBottomColor={'#54595F'}>
                                    <Text color={'black'} mr={.5} fontSize={'sm'}>{orderDetail.Number}</Text>
                                    <Text color={'black'} mr={.5} fontSize={'sm'}>{orderDetail.BoxIbox == BOX_ORDER_TYPE ? '(Box)' : orderDetail.BoxIbox == IBOX_ORDER_TYPE ? '(iBox)' : !stringIsNullOrEmpty(orderDetail.BoxIbox) ? '(' + orderDetail.BoxIbox + ')' : orderDetail.BoxIbox}</Text>
                                    <Text color={'black'} mr={.5} fontSize={'sm'}>{orderDetail.Pools.map(pool => pool.shortCode)}</Text>
                                    {
                                        orderDetail.AmountDetails.map(orderTypeAmount =>
                                            <Text color={'black'} mr={.5} fontSize={'sm'}>
                                                {orderTypeAmount.Type}{orderTypeAmount.Amount}
                                            </Text>
                                        )
                                    }
                                    <Spacer />
                                    <Text color={'#08CD34'} mr={.5} fontSize={'sm'} fontWeight={'bold'}>{`RM ${numberWithCurrencyFormat(orderDetail.Total)}`}</Text>
                                    <Button
                                        variant={'link'}
                                        onClick={() => {
                                            _dispatch(showCustomDialog({
                                                success: true,
                                                title: t('CONFIRMATION'),
                                                content: t('DELETE_NUMBER_CONFIRMATION') + orderDetail.Number,
                                                onConfirm: () => {
                                                    let newBetItem = [...betItem];
                                                    let currOrderModeIndex = newBetItem.findIndex(b => b.Type == selectedOrderMode.toLowerCase());

                                                    newBetItem[currOrderModeIndex]['OrderDetails'].splice(index, 1);

                                                    if (newBetItem[currOrderModeIndex]['OrderDetails'].length == 0) {
                                                        newBetItem.splice(currOrderModeIndex, 1);
                                                        setSelectedDrawDate([availableDrawDate[0]]);
                                                    }

                                                    setBetItem(newBetItem);
                                                },
                                                onCancel: () => { },
                                                horizontalBtn: false
                                            }))
                                        }}>
                                        <Image boxSize={'20px'} src={require('./assets/images/delete.png')} />
                                    </Button>
                                </Flex>
                            )
                        })
                    }
                </Stack>
            }
            {
                ticketTotal > 0 &&
                <Flex className="bottom-sticky-button" bg={'green'} justify={'center'} style={{ bottom: keyboardShown ? '27vh' : 0 }}>
                    <Button
                        color={'white'}
                        onClick={() => {
                            if (ticketTotal > selectedPlayer.gameScore) {
                                _dispatch(showCustomDialog({ success: false, content: t('INSUFFICIENT_CREDIT') }));
                            }
                            else {
                                _dispatch(showCustomDialog({
                                    success: true,
                                    title: t('CONFIRMATION'),
                                    content: t('CONFIRM_ORDER_CONFIRMATION') + "RM " + numberWithCurrencyFormat(ticketTotal),
                                    onConfirm: () => {
                                        submitBet();
                                    },
                                    onCancel: () => { },
                                    horizontalBtn: false
                                }))
                            }
                        }}
                    >
                        {t('CONFIRM_ORDER') + " RM " + numberWithCurrencyFormat(ticketTotal)}
                    </Button>
                </Flex>
            }
            <Keyboard
                layout={{
                    'default': [
                        "1 2 3 {back} 4 5 6 {hide} 7 8 9 {lucky} {prev} 0 {next} {add}"
                    ]
                }}
                display={{
                    '{add}': `${t("ADD")}`,
                    '{prev}': `${t("PREVIOUS")}`,
                    '{next}': `${t("NEXT")}`,
                    '{lucky}': `<i class='fas fa-star'></i>${t("LUCKY")}`,
                    '{back}': "<i class='fas fa-backspace'></i>",
                    '{hide}': "<i class='fas fa-chevron-down'></i>"
                }}
                theme={`keyboard keyboard-bet ${keyboardShown ? "keyboard-shown" : ""}`}
                keyboardRef={r => (keyboard.current = r)}
                onChange={onKeypadChange}
                onKeyPress={onKeypadKeyPress}
                disableButtonHold
                disableCaretPositioning
            />
            <CustomPicker
                showImg={false}
                isVisible={showOrderTypePicker}
                title={'SELECT_ORDER_TYPE'}
                options={_ORDER_TYPE_OPTION}
                selectedOption={orderType}
                onSelect={(isSelected = true, value) => {
                    if (isSelected) {
                        setOrderType(value);
                    }
                    setShowOrderTypePicker(!showOrderTypePicker);
                }}
            />
        </div>
    )
}